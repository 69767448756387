<template>
    <body class="detailPage">
        <advance-search v-if="$store.state.advanceSearch.showProductSearch"/>
        <main-header/>
        <div id="wrapper">
            <main class="productdetail-main-pb">
                <section>
                    <div class="container-fluid">
                        <h1>{{selectedProduct.name}}<a href="javascript:;" class="goback" @click="setProductDetailActivePage('home')">Back</a></h1>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div id="productDetailSlideShow" class="carousel slide" data-ride="carousel">                               
                                    <!-- The slideshow -->
                                    <div class="carousel-inner">
                                        <div class="carousel-item" :class="{'active':index==0}" v-for="(img,index) in allImages" :key="img.uuid">
                                            <img :src="loadImage(img.uuid)" alt="c" height="500px" width="100%">
                                        </div>
                                    </div>
                                    <!-- Left and right controls -->
                                    <a class="carousel-control-prev" href="#productDetailSlideShow" data-slide="prev" v-if="showArrows">
                                        <span class="carousel-arrows"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
                                    </a>
                                    <a class="carousel-control-next" href="#productDetailSlideShow" data-slide="next" v-if="showArrows">
                                        <span class="carousel-arrows"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="brandLogo"> </div>
                                <div class="productSpec">
                                    <h2>SPECIFICATIONS</h2>
                                    <p>{{selectedProduct.specifications}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
               <section>  <!-- -if="types.length>0" -->
                    <div class="container-fluid">
                        <div class="row">
                            <div id="app-serial-detail" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="gateway type5">
                                    <h2>PRODUCT TYPES</h2>
                                    <div id="app-tab-list" class="row">
                                        <a class="gwitem col-lg-2 col-md-4 col-sm-6 col-xs-12 cursor-pointer" v-for="(type,index) in types" :key="index" @click="setProductDetailActivePage('typeDetail',type)">
                                            <figcaption class="svghover">
                                                <img :src="loadImage(type.mainImageUuid)" alt="c"> 
                                                <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>
                                            </figcaption>
                                            <figure>
                                                <span>{{type.name}}</span>
                                            </figure>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
        <footer>
            <main-footer/>
        </footer>
    </body>
</template>

<script>
import advanceSearch from '../AdvanceSearch/advanceSearch';
import mainFooter from '../MainFooter/mainFooter';
import mainHeader from '../MainHeader/mainHeader';
import axios from '../../axios-auth';
export default {
    name:'productDetail',
    components:{
        'advance-search':advanceSearch,
        'main-footer':mainFooter,
        'main-header':mainHeader
    },
    data(){
        return {
            selectedProduct:'',
            allImages:[],
            types:[],
            showArrows:false
        }
    },
    methods:{
        setProductDetailActivePage: function(param,obj){
            if(param == 'productList'){
                this.$router.push('/productList');
            } else if(param == 'typeDetail'){
                this.$router.push('/productTypeDetail');
                localStorage.setItem("selectedType",JSON.stringify(obj));
            } else{
                this.$router.back()
                //this.$router.push('/');
            }
        },
        getSetStoreData:function(forC){
            if(forC == 'advanceSearchReset'){
                this.$store.state.advanceSearch.showProductSearch = true;
            }
        },
        getDetailViewInfo:function(prod) {
            console.error("prod",prod)
            axios.get('/products/'+prod.uuid).then(res =>{
                if(res.status == 200){
                    this.selectedProduct = res.data;
                    // if(res.data.mainImageUuid){
                    //     this.allImages.push({'uuid':res.data.mainImageUuid});
                    // }
                    res.data.productImages.forEach((img) => {
                        if(img.imageUrl){
                            this.allImages.push({'uuid':img.imageUrl});
                        }
                    });
                    setTimeout(()=>{
                        this.showArrows = true;
                    },1000)
                }
            });
        },
        getProductTypes:function(prod) {
            axios.get('/products/'+prod.uuid+'/productTypes').then(res =>{
                if(res.status == 200){
                    console.log("Res List",res);
                    this.types = res.data;
                    localStorage.setItem("selectedRelatedType",JSON.stringify(this.types));
                }
            });
        },
        loadImage(uuid) {  
            return this.$store.state.imageViewBaseUrl+uuid+'.jpg';
        }
    },
    mounted(){
        this.getDetailViewInfo(JSON.parse(localStorage.getItem('selectedProduct')));
        this.getProductTypes(JSON.parse(localStorage.getItem('selectedProduct')));
        window.scrollTo(500, 0);
    }
}
</script>

<style scoped>
</style>