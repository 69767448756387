<template>
    <span>
        <section class="bottomSocial">
            <div class="container-fluid">
                <ul>
                    <li><a target="_blank" href="https://www.facebook.com/Kale.Pakistan/"><span class="ion-social-facebook"><i class="fab fa-facebook-f"></i></span></a></li>
                    <li><a target="_blank" href="https://www.instagram.com/kalepakistanofficial/?hl=en"><span class="ion-social-instagram"><i class="fab fa-instagram-square"></i></span></a></li>
                    <li><a target="_blank" href="https://www.linkedin.com/company/kale-pakistan/"><span class="ion-social-linkedin"><i class="fab fa-linkedin"></i></span></a></li>
                </ul>
            </div>
        </section>
        <section>
            <div class="container-fluid">
                <ul class="nfm">
                    <li @click="displayFooterItems('know_more')"><a href="javascript:;">Know More</a>
                        <ul class="know_more">
                            <li><a href="javascript:;" @click="setMainFooterActivePage('aboutUs')">About Us</a></li>
                            <li><a href="javascript:;" @click="setMainFooterActivePage('catalogues')">Catalogues</a></li>
                            <li><a href="javascript:;" @click="setMainFooterActivePage('contactUs')">Contact Us</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:;">Information</a>
                        <ul>
                            <li><a href="javascript:;" @click="setMainFooterActivePage('faq')">Frequently Asked Questions</a></li>
                        </ul>
                    </li>
                    <li @click="displayFooterItems('top_selling')">
                        <a href="javascript:;">Top Selling</a>
                        <ul class="top_selling">
                            <li v-for="(item,index) in meta.topSelling" :key="index" @click="selectFavProduct(item)"><a href="javascript:;" >{{item.name}}</a></li>
                        </ul>
                    </li>
                    <!-- <li @click="displayFooterItems('hot_fav')">
                        <a href="javascript:;">Hot Favourites</a>
                        <ul class="hot_fav">
                            <li v-for="(item,index) in meta.hotFav" :key="index" @click="selectFavProduct(item)"><a href="javascript:;" >{{item.name}}</a></li>
                        </ul>
                    </li> -->
                    <li @click="displayFooterItems('new_series')">
                        <a href="javascript:;">New Series</a>
                        <ul class="new_series">
                            <li v-for="(item,index) in meta.newSeries" :key="index" @click="selectFavProduct(item)"><a href="javascript:;" >{{item.name}}</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:;">SUBSCRIBE TO NEWSLETTER</a>
                        <input type="email" class="form-control" placeholder="Email" v-model="email">
                        <div class="mr10">
                            <button type="button" class="app-btn app-btn-primary w100" @click="subscribeNewsLetter()">Subscribe</button>
                        </div>
                        <div class="newsletter" v-if="subscribeSuccess">
                            <span class="newsletter-success"><i class="fa fa-check-circle" aria-hidden="true"></i></span>
                        </div>
                    </li>  
                </ul>
            </div>
        </section>
    </span>
</template>

<script>
import axios from '../../axios-auth';
import $ from 'jquery';
export default {
    name:'MainFooter',
    data(){
        return{
            email:'',
            subscribeSuccess:false,
            meta:{
                newSeries:[],
                hotFav:[],
                topSelling:[]
            }
        }
    },
    methods:{
        setMainFooterActivePage:function(toRoute){
            if(toRoute == 'aboutUs'){
                this.$router.push('/aboutUs');
            } else if(toRoute == 'catalogues'){
                this.$router.push('/catalogues');
            } else if(toRoute == 'contactUs'){
                this.$router.push('/contactUs');
            } else if(toRoute == 'faq'){
                this.$router.push('/faq');
            }
        },
        getMetaData:function() {
            axios.get('superProducts').then(res=>{
                if(res.status == 200){
                    res.data.forEach((prod) =>{
                        prod.uuid = prod.productUuid;
                        if(prod.type == 'New Series'){
                            this.meta.newSeries.unshift(prod);
                        } else if(prod.type == 'Hot Favourites'){
                            this.meta.hotFav.unshift(prod);
                        } else if(prod.type == 'Top Selling'){
                            this.meta.topSelling.unshift(prod);
                        }
                    });
                }
            });
        },
        displayFooterItems:function(param) {
            if(param == 'know_more'){
                $('.'+param).addClass('footer-display');
            } else if(param == 'top_selling'){
                $('.'+param).addClass('footer-display');
            } else if(param == 'hot_fav'){
                $('.'+param).addClass('footer-display');
            } else if(param == 'new_series'){
                $('.'+param).addClass('footer-display');
            }  
        },
        subscribeNewsLetter:function() {
            var data = {
                email:this.email
            }
            axios.post('/addSubscriber',data).then(res=>{
                if(res.status == 200){
                    this.email = '';
                    this.subscribeSuccess = true;
                    setTimeout(()=>{
                        this.subscribeSuccess = false;
                    },3000);
                }
            });
        },
        selectFavProduct:function(prod) {
            localStorage.setItem("selectedProduct",JSON.stringify(prod));
            this.$router.push('/productDetail');
        }
    },
    mounted(){
        this.getMetaData();
    }
}
</script>