<template>
    <header>
        <div class="container-fluid ">
            <div id="logo">
                <a href="javascript:;" @click="setMainHeaderActivePage('home')"><img title="KALE" src="../../assets/images/site/kale-logo-black.png" alt="Kale Logo"></a>
            </div>
            <div class="headerContent">
                <div class="headerTop">
                    <div class="siteStaff">
                        <ul>
                            <li @click="getSetMainHeaderStoreData('advanceSearchReset')">
                                <a href="javascript:;" class="detailSearch">Product Search
                                    <span class="ion-android-search">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="headerBottom">
                    <!-- <div class="dropdown brandChoice headerBrandsList">
                        <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown">CHOOSE BRAND</a>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="#">Link 1</a>
                            <a class="dropdown-item" href="#">Link 2</a>
                            <a class="dropdown-item" href="#">Link 3</a>
                        </div>
                    </div> -->
                    <div class="mainMenu headerBrandsList">
                        <ul id="zone_135">
                            <li id="article_263" class="lie"><a title="CONTACT US" href="javascript:;" @click="setMainHeaderActivePage('contactUs')"><span>CONTACT US</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    methods:{
        setMainHeaderActivePage:function(toRoute){
            if(toRoute == 'contactUs'){
                this.$router.push('/contactUs');
            } else if(toRoute == 'home'){
                this.$router.push('/');
            }
        },
        getSetMainHeaderStoreData:function(forC){
            if(forC == 'advanceSearchReset'){
                this.$store.state.advanceSearch.showProductSearch = true;
            }
        }
    }
}
</script>