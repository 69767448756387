<template>
    <body id="contact">
         <advance-search v-if="$store.state.advanceSearch.showProductSearch"/>
         <main-header/>
        <div id="wrapper">
            <main class="contactus-main-pb">
                <section>
                    <div class="container-fluid">
                        <h1>CONTACT US
                            <a href="javascript:;" class="goback" @click="setContactUsActivePage('home')">Back</a>
                        </h1>
                    </div>
                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="mapContainer">
                                    <img src="../../assets/images/4_1_kale_iletisim_banner.jpg">
                                </div>
                                <div class="contactinfobar">
                                    <h5><span class="ion-ios-telephone"></span>CUSTOMER SERVICE</h5>
                                    <ul>
                                        <li><a href="mailto:customerservices@kale.com.tr">cs@kale.com.pk</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <h3>HEADQUARTERS</h3>
                                <h4>LAHORE</h4>
                                <p>
                                    <strong>Address:</strong> 71-Main Boulevard Phase 6, DHA<br>
                                    <strong>Phone:&nbsp;</strong>
                                    <a href="javascript:;"></a>
                                </p>
                                <p>
                                    <strong>Address:</strong> 40-A, 1-C1, Collage Road Township<br>
                                    <strong>Phone:&nbsp;</strong>
                                    <a href="javascript:;"></a>
                                </p>

                                <h4>ISLAMABAD</h4>
                                <p>
                                    <strong>Address:</strong> Opposite Giga Mall, GT Road<br>
                                    <strong>Phone:&nbsp;</strong>
                                    <a href="javascript:;"></a>
                                </p>

                                <h4>MULTAN</h4>
                                <p>
                                    <strong>Address:</strong> 2361-New Shalimar Colony, Near Mall of Multan<br>
                                    <strong>Phone:&nbsp;</strong>
                                    <a href="javascript:;"></a>
                                </p>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <h3>HEADQUARTERS</h3>
                                <h4>KARACHI</h4>
                                <p>
                                    <strong>Address:</strong> 25-C, Lane 9, Bukhari Commercial, Phase 6 DHA<br>
                                    <strong>Phone:&nbsp;</strong>
                                    <a href="javascript:;"></a>
                                </p>
                                <h4>FAISALABAD</h4>
                                <p>
                                    <strong>Address:</strong> P12 Susan Road, Madina Tower Near Hockey Stadium<br>
                                    <strong>Phone:&nbsp;</strong>
                                    <a href="javascript:;"></a>
                                </p>
                                <h4>GUJRANWALA</h4>
                                <p>
                                    <strong>Address:</strong> Opposite Eventures Marquee Divisional Superintendent Post Office. Main GT Road<br>
                                    <strong>Phone:&nbsp;</strong>
                                    <a href="javascript:;"></a>
                                </p>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h3>CONTACT FORM</h3>
                            </div>

                            <form class="contactFormSalesforce generalForm" novalidate="novalidate" @submit.stop.prevent>
                                <input type="hidden" name="plugin" value="salesforce">
                                <input type="hidden" name="articleId" value="2564">
                                <input type="hidden" class="cid" name="captchaid" value="3429">

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="frow">
                                        <label for="" class="col-md-4 col-sm-4 col-xs-12">NAME</label>
                                        <input class="col-md-8 col-sm-8 col-xs-12 form-control" type="text" name="fullName">
                                    </div>
                                    <div class="frow">
                                        <label for="" class="col-md-4 col-sm-4 col-xs-12">EMAIL</label>
                                        <input class="col-md-8 col-sm-8 col-xs-12 form-control" type="text" name="email">
                                    </div>
                                    <div class="frow">
                                        <label for="" class="col-md-4 col-sm-4 col-xs-12">SUBJECT</label>
                                        <input class="col-md-8 col-sm-8 col-xs-12 form-control" type="text" name="subject">
                                    </div>
                                    <div class="frow">
                                        <label for="" class="col-md-4 col-sm-4 col-xs-12">COMMENTS</label>
                                        <textarea class="col-md-8 col-sm-8 col-xs-12 form-control" name="comments" id="comments" cols="30" rows="10"></textarea>
                                    </div>
                                    <div class="frow">
                                        <label for=""><small><input type="checkbox" name="eileti"></small><small> I would like to receive an email/sms or call containing information about the products, special offers and events from Kale.</small></label>
                                    </div>
                                    <div class="frow">
                                        <input type="submit" value="SEND" class="btn btn-primary pull-left">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </main>
        </div>
        <!--wrapper-->
        <footer>
            <main-footer/>
        </footer>
    </body>
</template>

<script>
import advanceSearch from '../AdvanceSearch/advanceSearch';
import mainFooter from '../MainFooter/mainFooter';
import mainHeader from '../MainHeader/mainHeader';
export default {
    components:{
        'advance-search':advanceSearch,
        'main-footer':mainFooter,
        'main-header':mainHeader
    },
    data(){
        return {

        }
    },
    methods:{
        setContactUsActivePage:function(toRoute){
            if(toRoute == 'home'){
                // this.$router.push('/');
                this.$router.back();
            }
        },
        getSetStoreData:function(forC){
            if(forC == 'advanceSearchReset'){
                this.$store.state.advanceSearch.showProductSearch = true;
            }
        },
    },
    mounted(){
        window.scrollTo(500, 0);
    }
}
</script>