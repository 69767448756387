<template>
    <div id="detailSearch" >
        <a href="javascript:;" class="searchClose ion-android-close" @click="onCloseSearch()"><i class="fa fa-times" aria-hidden="true"></i></a>
        <div class="container-fluid">
            <div class="detailSearchContent" id="app-search">
                <ul class="tabNav">
                    <li><a href="javascript:;" :class="{'active':advanceSearchObj.type=='textSearch'}" @click="onSelectTopSearchType('textSearch')">TEXT SEARCH</a></li>
                    <li><a href="javascript:;" :class="{'active':advanceSearchObj.type=='productSearch'}" @click="onSelectTopSearchType('productSearch')">SEARCH BY CATEGORY</a></li>
                    <!-- <li><a href="javascript:;" :class="{'active':advanceSearchObj.type=='detailSearch'}" @click="onSelectTopSearchType('detailSearch')">DETAILED SEARCH</a></li> -->
                </ul>
                <div class="tabItem gateway" id="ks" v-if="advanceSearchObj.type=='textSearch'" style="display:block">
                    <div class="row">
                        <form id="app-search-by-keyword" class="dtkeywordsearch col-lg-12 col-md-12 col-sm-12 col-xs-12" action="">
                            <div class="frow">
                                <input type="text" placeholder="SEARCH" v-model="advanceSearchObj.textSearchObj.productName">
                                <button type="button" @click="onSearchProduct()"><i class="fa fa-search" aria-hidden="true"></i></button>
                            </div>
                        </form>
                    </div>
                    <div class="app-product-type-list row mt10">
                        <a class="col-md-2 col-sm-3 col-xs-6 gwitem" href="javascript:;" v-for="prod in advanceSearchObj.list" :key="prod.id" @click="selectSearchedProduct(prod)">
                            <figcaption class="svghover">
                                <img :src="loadImage(prod.mainImageUuid)" alt="c">
                                <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>
                            </figcaption>
                            <figure>
                                <span>{{prod.name}}</span>
                            </figure>
                        </a>
                    </div>
                </div>
                <div class="tabItem gateway" id="pts" v-if="advanceSearchObj.type=='productSearch'">
                    <div class="app-product-type-list row">
                        <a class="col-md-2 col-sm-3 col-xs-6 gwitem" href="javascript:;" v-for="cat in advanceSearchObj.categoriesList" :key="cat.id" @click="selectSearchedCategory(cat)">
                            <figcaption class="svghover">
                                <img :src="loadImage(cat.mainImageUuid)" alt="c">
                                <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>
                            </figcaption>
                            <figure>
                                <span>{{cat.name}}</span>
                            </figure>
                        </a>
                    </div>
                </div>
                <!-- <div class="tabItem gateway" id="ds" v-if="advanceSearchObj.type=='detailSearch'">
                    <form action="/tr/urun-arama" class="app-search-form forms" id="detailProductSrc">
                        <div class="row">
                            <div class="productFiltercontent active pt">
                                <label for="" class="col-md-4 col-sm-4 col-xs-12">CHOOSE PRODUCT TYPE</label>
                                <div class="col-md-8 col-sm-8 col-xs-12">
                                    <select name="productType" id="productType">
                                        <option>Choose</option>
                                        <option value="13">Ceramic Tiles</option>
                                        <option value="13">Ceramic Tiles</option>
                                        <option value="13">Ceramic Tiles</option>
                                        <option value="13">Ceramic Tiles</option>
                                        <option value="13">Ceramic Tiles</option>
                                        <option value="8">Sanitaryware</option>
                                        <option value="7">Faucets</option>
                                        <option value="1284">Sinterflex</option>
                                    </select>
                                </div>
                            </div>
                            <div class="productFiltercontent spt">
                                <label for="" class="col-md-4 col-sm-4 col-xs-12">CHOOSE SUB-PRODUCT TYPE</label>
                                <div class="col-md-8 col-sm-8 col-xs-12">
                                    <select name="subproducttype" id="subproducttype">
                                    </select>
                                </div>
                            </div>
                            <div class="productFiltercontent size">
                                <label for="" class="col-md-4 col-sm-4 col-xs-12">CHOOSE SIZE</label>
                                <div class="col-md-8 col-sm-8 col-xs-12">
                                    <div class="filterGroup">
                                        <div class="fgrow">
                                            <div class="fgr app-size-options">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="productFiltercontent color">
                                <label for="" class="col-md-4 col-sm-4 col-xs-12">CHOOSE COLOUR</label>
                                <div class="col-md-8 col-sm-8 col-xs-12">
                                    <div class="filterGroup">
                                        <div class="fgrow">
                                            <div class="fgr app-color-options">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="productFiltercontent active">
                                <input type="submit" value="SEARCH" class="dtsb">
                            </div>
                        </div>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import axios from '../../axios-auth';
export default {
    data(){
        return{
            advanceSearchObj:{
                type:'textSearch',
                textSearchObj:{},
                paginationData:{},
                list:[],
                categoriesList:[]
            }
        }
    },
    methods:{
        getAllCategories:function() {
            axios.get('/category-list').then(res=>{
                if(res.status == 200){
                    this.advanceSearchObj.categoriesList = res.data;
                    console.log("category",this.advanceSearchObj.categoriesList)
                }
            });
        },
        onSelectTopSearchType:function(type){
            if(this.advanceSearchObj.type == type){
                return;
            }
            this.advanceSearchObj.type = type;
            this.setSearchData();
        },
        setSearchData:function(){
            this.advanceSearchObj.list = [];
            this.advanceSearchObj.textSearchObj.productName = '';
            this.advanceSearchObj.textSearchObj.categoryName = '';
            this.advanceSearchObj.totalPages = [];
            this.advanceSearchObj.paginationData.page = 0;
            this.advanceSearchObj.paginationData.size = 10;
        },
        onCloseSearch:function(){
            this.$store.commit('onSetAdvanceSearch');
            this.advanceSearchObj.type = 'textSearch';
            this.setSearchData();
        },
        onSearchProduct:function() {
            if(this.advanceSearchObj.type == 'textSearch' || this.advanceSearchObj.type == 'productSearch'){
                this.continueSearchProduct(this.advanceSearchObj.paginationData.page,this.advanceSearchObj.paginationData.size,this.advanceSearchObj.textSearchObj.productName,this.advanceSearchObj.textSearchObj.categoryName);
            } else{
                this.continueDetailSearch();
            }
        },
        continueSearchProduct:function(page,size,productName,categoryName){
            axios.get('/searchProducts?page='+page+'&offset='+size+'&productName='+productName+'&categoryName='+categoryName).then(res=>{
                if(res.status == 200){
                    this.advanceSearchObj.list = res.data.content;
                }
            });
        },
        continueDetailSearch:function() {
            // axios.get('/searchProducts',data).then(res=>{
            //     console.log("Res adv",res)
            // });
        },
        selectSearchedCategory:function(obj) {
            this.onCloseSearch();
            localStorage.setItem("selectedCategory",JSON.stringify(obj));
            this.$router.push('/productList');
        },
        selectSearchedProduct:function(prod) {
            this.onCloseSearch();
            localStorage.setItem("selectedProduct",JSON.stringify(prod));
            this.$router.push('/productDetail');
        },
        loadImage(uuid) {
           return this.$store.state.imageViewBaseUrl+uuid+'.jpg';
        }
    },
    mounted(){
        this.getAllCategories();
        this.setSearchData();
    }
}
</script>