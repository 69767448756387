<template>
    <body class="detailPage">
        <advance-search v-if="$store.state.advanceSearch.showProductSearch"/>
        <main-header/>
        <div id="wrapper">
            <main class="productdetail-main-pb">
                <section>
                    <div class="container-fluid">
                        <h1>{{selectedType.name}}<a href="javascript:;" class="goback" @click="setProductDetailActivePage()">Back</a></h1>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div id="productDetailSlideShow" class="carousel slide" data-ride="carousel">                               
                                    <!-- The slideshow -->
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img :src="loadImage(selectedType.mainImageUuid)" alt="c" height="500px" width="100%">
                                        </div>
                                    </div>
                                    <!-- Left and right controls -->
                                    <!-- <a class="carousel-control-prev" href="#productDetailSlideShow" data-slide="prev">
                                        <span class="carousel-arrows"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
                                    </a>
                                    <a class="carousel-control-next" href="#productDetailSlideShow" data-slide="next">
                                        <span class="carousel-arrows"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
                                    </a> -->
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="brandLogo"> </div>
                                <div class="productSpec">
                                    <h2>SPECIFICATIONS</h2>
                                    <p>{{selectedType.specifications}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
               <section>
                    <div class="container-fluid">
                        <div class="row">
                            <div id="app-serial-detail" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="gateway type5">
                                    <h2>PRODUCT TYPES</h2>
                                    <div id="app-tab-list" class="row">
                                        <a class="gwitem col-lg-2 col-md-4 col-sm-6 col-xs-12 cursor-pointer" v-for="(type,index) in relatedTypes" :key="index" @click="onSelectOtherType(type)">
                                            <figcaption class="svghover">
                                                <img :src="loadImage(type.mainImageUuid)" alt="c"> 
                                                <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>
                                            </figcaption>
                                            <figure>
                                                <span>{{type.name}}</span>
                                            </figure>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
        <footer>
            <main-footer/>
        </footer>
    </body>
</template>

<script>
import advanceSearch from '../AdvanceSearch/advanceSearch';
import mainFooter from '../MainFooter/mainFooter';
import mainHeader from '../MainHeader/mainHeader';
export default {
    name:'productTypeDetail',
    components:{
        'advance-search':advanceSearch,
        'main-footer':mainFooter,
        'main-header':mainHeader
    },
    data(){
        return {
            selectedType:{
                name:''
            },
            relatedTypes:null
        }
    },
    methods:{
        setProductDetailActivePage:function(){
            this.$router.back();
        },
        onSelectOtherType:function(type){
            this.selectedType = type;
            window.scrollTo(500, 0);
        },
        loadImage:function(uuid) {
            return this.$store.state.imageViewBaseUrl+uuid+'.jpg';
        }
    },
    mounted(){
        this.selectedType = JSON.parse(localStorage.getItem('selectedType'));
        this.relatedTypes = JSON.parse(localStorage.getItem('selectedRelatedType'));
        window.scrollTo(500, 0);
    }
}
</script>

<style scoped>
</style>