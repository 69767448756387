<template>
    <body>
        <advance-search v-if="$store.state.advanceSearch.showProductSearch"/>
        <main-header/>
        <div id="wrapper">
            <main class="catalogue-main-pb">
                <section>
                    <div class="container-fluid">
                        <h1>Catalogues<a href="javascript:;" class="goback">Back</a>
                            <a href="javascript:;" class="goback" @click="setCatalogueActivePage('home')">Back</a>
                        </h1>
                    </div>
                    <!-- <section>
                        <div class="container-fluid">
                            <div class="littlenav">
                                <ul id="zone_153">
                                    <li id="article_275" class="active"><a title="Catalogues" href="/en/media/catalogues"><span>Catalogues</span></a>
                                    </li>
                                    <li id="article_276"><a title="Quality Certificates" href="/en/media/quality-certificates"><span>Quality Certificates</span></a>
                                    </li>
                                    <li id="article_2777" class="lie"><a title="Press Releases" href="/en/media/press-releases"><span>Press Releases</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section> -->
                    <div class="container-fluid qualityDocs">
                        <div class="accordion">
                            <div class="accItem" @click="selectCatalogue('1')">
                                <span class="ion-android-arrow-dropdown cataButtonFocus" :class="{'catalog-bg':cataloguesObj.log1}">
                                    <b-button v-b-toggle.collapse-1 variant="link" toggle-class="text-decoration-none" no-caret> <small>Bathroom Catalogues</small></b-button>
                                    <b-collapse id="collapse-1" class="mt-2 p5">
                                        <b-card>
                                        <div class="accPane" style="display: block;">
                                            <div class="gateway type3">
                                                <div class="row">
                                                    <a class="gwitem col-lg-2 col-md-2 col-sm-4 col-xs-6" href="https://www.kale.com.tr/i/content/316_2_ceramic-sanitary-ware.pdf">
                                                        <figcaption class="svghover"> <img src="https://www.kale.com.tr/i/content/316_1_ceramic-sanitary-ware-1.png" alt="2_ceramic-sanitary-ware.pdf"> <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>                                                </figcaption>
                                                        <figure> <small>29,4 MB</small><span>Ceramic Sanitary Ware</span> </figure>
                                                    </a>
                                                    <a class="gwitem col-lg-2 col-md-2 col-sm-4 col-xs-6" href="https://www.kale.com.tr/i/content/316_2_2020-export.pdf">
                                                        <figcaption class="svghover"> <img src="https://www.kale.com.tr/i/content/316_1_2020-export-1.png" alt="2_2020-export.pdf"> <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>                                                </figcaption>
                                                        <figure> <small>13,2 MB</small><span>2020 Export</span> </figure>
                                                    </a>
                                                    <a class="gwitem col-lg-2 col-md-2 col-sm-4 col-xs-6" href="https://www.kale.com.tr/i/content/316_2_694_2_smarthijyen_brosur-en.pdf">
                                                        <figcaption class="svghover"> <img src="https://www.kale.com.tr/i/content/316_1_694_1__hijyen.png" alt="2_694_2_smarthijyen_brosur-en.pdf"> <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>                                                </figcaption>
                                                        <figure> <small> 303 KB</small><span> SmartHygiene Brochure</span> </figure>
                                                    </a>
                                                    <a class="gwitem col-lg-2 col-md-2 col-sm-4 col-xs-6" href="https://www.kale.com.tr/i/content/316_2_694_2_SmartBrosurENV3.pdf">
                                                        <figcaption class="svghover"> <img src="https://www.kale.com.tr/i/content/316_1_694_1_smart-kapak.png" alt="2_694_2_SmartBrosurENV3.pdf"> <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>                                                </figcaption>
                                                        <figure> <small> 1.11 MB</small><span> Kale Smart Products Brochures</span> </figure>
                                                    </a>
                                                    <a class="gwitem col-lg-2 col-md-2 col-sm-4 col-xs-6" href="https://www.kale.com.tr/i/content/316_2_bathroom-furniture.pdf">
                                                        <figcaption class="svghover"> <img src="https://www.kale.com.tr/i/content/316_1_bathroom-furniture-1.png" alt="2_bathroom-furniture.pdf"> <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>                                                </figcaption>
                                                        <figure> <small>30,9 MB</small><span>Bathroom Furniture </span> </figure>
                                                    </a>
                                                    <a class="gwitem col-lg-2 col-md-2 col-sm-4 col-xs-6" href="https://www.kale.com.tr/i/content/316_2_faucet-and-accessories.pdf">
                                                        <figcaption class="svghover"> <img src="https://www.kale.com.tr/i/content/316_1_faucet-and-accessories-1.png" alt="2_faucet-and-accessories.pdf"> <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>                                                </figcaption>
                                                        <figure> <small>16,5 MB</small><span>Faucet and Accessories</span> </figure>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        </b-card>
                                    </b-collapse>
                                </span>
                            </div>
                            <div class="accItem" @click="selectCatalogue('2')">
                                <span class="ion-android-arrow-dropdown cataButtonFocus" :class="{'catalog-bg':cataloguesObj.log2}">
                                    <b-button v-b-toggle.collapse-2 variant="link"> <small>Ceramic Catalogues</small></b-button>
                                    <b-collapse id="collapse-2" class="mt-2 p5">
                                        <b-card>
                                        <div class="accPane" style="display: block;">
                                            <div class="gateway type3">
                                                <div class="row">
                                                    <a class="gwitem col-lg-2 col-md-2 col-sm-4 col-xs-6" href="https://www.kale.com.tr/i/content/196_2_t-one-by-kale-en.pdf">
                                                        <figcaption class="svghover"> <img src="https://www.kale.com.tr/i/content/196_1_t-one-by-kale-img.png" alt="2_t-one-by-kale-en.pdf"> <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>                                                </figcaption>
                                                        <figure> <small></small><span>T-ONE</span> </figure>
                                                    </a>
                                                    <a class="gwitem col-lg-2 col-md-2 col-sm-4 col-xs-6" href="https://www.kale.com.tr/i/content/196_2_canakkale-seramik-2020.pdf">
                                                        <figcaption class="svghover"> <img src="https://www.kale.com.tr/i/content/196_1_canakkale-seramik-2020-new.png" alt="2_canakkale-seramik-2020.pdf"> <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>                                                </figcaption>
                                                        <figure> <small></small><span>Çanakkale Seramik</span> </figure>
                                                    </a>
                                                    <a class="gwitem col-lg-2 col-md-2 col-sm-4 col-xs-6" href="https://www.kale.com.tr/i/content/196_2_kalebodur-katalog-2020.pdf">
                                                        <figcaption class="svghover"> <img src="https://www.kale.com.tr/i/content/196_1_kalebodur-katalog-2020.png" alt="2_kalebodur-katalog-2020.pdf"> <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>                                                </figcaption>
                                                        <figure> <small></small><span>Kalebodur</span> </figure>
                                                    </a>
                                                    <a class="gwitem col-lg-2 col-md-2 col-sm-4 col-xs-6" href="https://www.kale.com.tr/i/content/196_2_kalesinterflex-catalogue-2021.pdf">
                                                        <figcaption class="svghover"> <img src="https://www.kale.com.tr/i/content/196_1_ksfx_th.jpg" alt="2_KSFX.pdf"> <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>                                                </figcaption>
                                                        <figure> <small></small><span>KSFX</span> </figure>
                                                    </a>
                                                    <a class="gwitem col-lg-2 col-md-2 col-sm-4 col-xs-6" href="https://www.kale.com.tr/i/content/196_2_Kalestone+.pdf">
                                                        <figcaption class="svghover"> <img src="https://www.kale.com.tr/i/content/196_1_kalestone_th.jpg" alt="2_Kalestone+.pdf"> <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>                                                </figcaption>
                                                        <figure> <small></small><span>Kalestone</span> </figure>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        </b-card>
                                    </b-collapse>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
        <footer class="catalogue-footer-mt">
            <main-footer/>
        </footer>
    </body>
</template>

<script>
import advanceSearch from '../AdvanceSearch/advanceSearch';
import mainFooter from '../MainFooter/mainFooter';
import mainHeader from '../MainHeader/mainHeader'
export default {
    components:{
        'advance-search':advanceSearch,
        'main-footer':mainFooter,
        'main-header':mainHeader
    },
    data(){
        return{
            cataloguesObj:{
                log1:false,
                log2:false
            }
        }
    },
    methods:{
        setCatalogueActivePage:function(toRoute){
            if(toRoute == 'home'){
                this.$router.push('/');
            }
        },
        selectCatalogue:function(catalog){
            if(catalog == '1'){
                this.cataloguesObj.log1 = !this.cataloguesObj.log1;
            } else{
                this.cataloguesObj.log2 = !this.cataloguesObj.log2;
            }
        },
        getSetStoreData:function(forC){
            if(forC == 'advanceSearchReset'){
                this.$store.state.advanceSearch.showProductSearch = true;
            }
        },
    },
    mounted(){
        window.scrollTo(500, 0);
    }
}
</script>