<template>
    <body id="home" class="home" @click="onLoginIconClick()">
        <advance-search v-if="$store.state.advanceSearch.showProductSearch"/>
        <header class="appHeader">
            <div class="container-fluid">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand pl-0 ml10 kale-home-main-logo" href="javasceipt:;"><img title="KALE" src="../../assets/images/kale-logo.png" alt="Kale Logo"></a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <div class="navbar-search" v-if="homeObj.showSearchField">
                        <form action="">
                            <input type="text" placeholder="SEARCH" name="keyword" autocomplete="off">
                            <input type="hidden" name="pageindex" value="1">
                            <button><i class="fa fa-search" aria-hidden="true"></i></button>
                        </form>
                    </div>
                    <ul class="navbar-nav ml-auto top-nav-actions">
                        <li class="nav-item">
                            <a title="Sales Points" href="javascript:;" class="nav-link" @click="getSetStoreData('advanceSearchReset')"><span>Product Search</span></a>
                        </li>
                        <li class="nav-item mt10">
                            <a href="javascript:;" @click="getSetStoreData('advanceSearchReset')"><i class="fa fa-search" aria-hidden="true"></i></a>
                        </li>
                        <li class="nav-item dropdown" v-if="!homeObj.loginDetail.logged && !$isMobile()">
                            <a class="nav-link dropdown-toggle" href="#javascript:;" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="onLoginIconClick()">
                                <i class="fas fa-user"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right loginMenu homeLoginUser" aria-labelledby="navbarDropdown">
                                <form class="px-4 py-3 w300px">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="exampleDropdownFormEmail1" placeholder="username" v-model="homeObj.loginDetail.userName">
                                    </div>
                                    <div class="form-group">
                                        <input type="password" class="form-control" id="exampleDropdownFormPassword1" placeholder="Password" v-model="homeObj.loginDetail.password">
                                    </div>
                                    <button type="submit" class="app-btn app-btn-primary w100" @click="loginUser()">Sign in</button>
                                </form>
                                <!-- <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">Something else here</a> -->
                            </div>
                        </li>
                        <li class="nav-item dropdown" v-if="homeObj.loginDetail.logged">
                            <a class="nav-link dropdown-toggle" href="#javascript:;" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{homeObj.loginDetail.logged.name}}
                            </a>
                            <div class="dropdown-menu dropdown-menu-right loginMenu" aria-labelledby="navbarDropdown">
                                <form class="px-4 py-3 w300px">
                                    <button type="submit" class="app-btn app-btn-primary w100" @click="logoutUser()">Logout</button>
                                </form>
                            </div>
                        </li>
                    </ul>
                    <!-- <div class="navbar-search" v-if="homeObj.showSearchField">
                        <form action="">
                            <input type="text" placeholder="SEARCH" name="keyword" autocomplete="off">
                            <input type="hidden" name="pageindex" value="1">
                            <button><i class="fa fa-search" aria-hidden="true"></i></button>
                        </form>
                    </div> -->
                </div>
            </nav>
            </div>
        </header>
        <div id="wrapper">
            <main class="mainMidBlock">
                <div class="container-fluid homeContainer mainHomeContainer">
                    <div id="app-brands-home" class="homeproductModule">
                        <div class="row">
                            <div class="brandMenu col-lg-3 col-md-3 col-sm-4 col-xs-12">
                                <h2>BRANDS</h2>
                                <ul class="app-brand-list">
                                    <li @click="productListView(brand)" v-for="brand in homeObj.brandsList" :key="brand.uuid">
                                        <span class="brand-link" data-id="72">{{brand.name}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="app-brand-detail col-lg-9 col-md-9 col-sm-8 col-xs-12 moduleDetail" v-if="homeObj.showCategoryList">
                                <a href="javascript:;" class="moduleCloser ion-chevron-left" @click="homeObj.showCategoryList=false"><i class="fa fa-chevron-left" aria-hidden="true"></i></a>
                                <span class="fheadline">{{homeObj.selectedBrand.name}}</span>
                                <div class="app-brand-detail-list gateway type5">
                                    <a class="col-lg-2 col-md-4 col-sm-6 col-xs-12 gwitem" href="javascript:;" v-for="cat in homeObj.categoryList" :key="cat.uuid" @click="setHomeActivePage('productList',cat)">
                                        <figcaption class="svghover">
                                            <img :src="loadImage(cat.mainImageUuid)" alt="c">
                                            <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>
                                        </figcaption>
                                        <figure>
                                            <span>{{cat.name}}</span>
                                        </figure>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="darkBg homeactivites" rel="etkinlik">
                    <div class="container-fluid">
                        <div class="gateway contentgateway">
                            <a class="col-lg-4 col-md-4 col-sm-6 col-xs-12 gwitem flag_1" href="javascript:;" @click="setHomeActivePage('event',proj)" v-for="proj in this.homeObj.showProjectsList" :key="proj.id">
                                <figcaption class="svghover">
                                    <img :src="loadImage(proj.mainImageUuid)" alt="c"> 
                                    <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>
                                </figcaption>
                                <figure>
                                    <span>{{proj.name}}</span>
                                    <p class="projectsList">{{proj.description}}</p>
                                    <small class="ion-arrow-right-c"></small>
                                </figure>
                            </a>
                        </div>
                    </div>
                    <div class="loadButtonContent" v-if="homeObj.loadMoreProjects"><a href="javascript:;" class="loadMore clearLinkAttr" @click="loadMoreProjects()">Load More</a></div>
                </section>
            </main>
        </div>
        <footer class="mainFooter">
            <main-footer/>
        </footer>
        <div class="homeBg">
            <div class="bgslider">
                <div class="one">
                    <!-- <div id="productDetailSlideShow" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img src="../../assets/images/mainImage1.jpg">
                            </div>
                            <div class="carousel-item">
                                <img src="../../assets/images/mainImage2.jpg">
                            </div>
                            <div class="carousel-item">
                                <img src="../../assets/images/mainImage3.jpg">
                            </div>
                            <div class="carousel-item">
                                <img src="../../assets/images/mainImage4.jpg">
                            </div>
                            <div class="carousel-item">
                                <img src="../../assets/images/mainImage5.jpg">
                            </div>
                            <div class="carousel-item">
                                <img src="../../assets/images/mainImage6.jpg">
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </body>
</template>

<script>
import axios from '../../axios-auth';
import $ from 'jquery';
import advanceSearch from '../AdvanceSearch/advanceSearch.vue';
import mainFooter from '../MainFooter/mainFooter';
export default {
    name: 'Home',
    props:{
        msg:{
            type: String,
            required: false
        }
    },
    components:{
        'advance-search':advanceSearch,
        'main-footer':mainFooter
    },
    data() {
        return {
            homeObj:{
                showCategoryList:false,
                brandsList:[],
                categoryList:[],
                projectsList:[],
                showProjectsList:[],
                loadMoreProjects:true,
                loginDetail:{
                    userName:'',
                    password:'',
                    logged:JSON.parse(localStorage.getItem('loggedInUser')) ? JSON.parse(localStorage.getItem('loggedInUser')) : null
                },
                showSearchField:true
            }
        }
    },
    methods:{
        setHomeActivePage: function(toRoute,obj){
            if(toRoute == 'login'){
                this.$router.push('/login');
            } else if(toRoute == 'productList'){
                if(this.homeObj.showCategoryList){
                    obj.listOf = 'categories';
                } else{
                    obj.listOf = 'products';
                }
                console.log("obj",obj)
                localStorage.setItem("selectedCategory",JSON.stringify(obj));
                this.$router.push('/productList');
            } else if(toRoute == 'event'){
                this.$store.state.events = obj;
                this.$router.push('/event');
            }
        },
        productListView:function(brand){
            this.homeObj.selectedBrand = brand;
            if(brand.routerInfo == 'category'){
                this.homeObj.showCategoryList = true;
                this.getCategoryList(brand.uuid);
            } else{
                this.homeObj.showCategoryList = false;
                this.setHomeActivePage('productList',brand);
            }
        },
        getSetStoreData:function(forC){
            if(forC == 'advanceSearchReset'){
                this.$store.state.advanceSearch.showProductSearch = true;
            }
        },
        getAllBrands:function(){
            axios.get('/brand-list').then(res => {
                console.error("res",res);
                this.homeObj.brandsList = res.data;
            });
        },
        getCategoryList:function(uuid){
            axios.get('/brands/'+uuid+'/category-list').then(res => {
                console.error("res",res);
                this.homeObj.categoryList = res.data;
            });
        },
        loadImage(uuid) {
            console.log("uuid",uuid)
            console.log("this.$store.state.imageViewBaseUrl+uuid+'.jpg'",this.$store.state.imageViewBaseUrl+uuid+'.jpg')
            return this.$store.state.imageViewBaseUrl+uuid+'.jpg';
        },
        getAllProjectsList:function(){
            axios.get('/projects').then(res => {
                console.error("res",res);
                this.homeObj.projectsList = res.data;
                for(var u=0;u<res.data.length;u++){
                    if(u>2){
                        break;
                    }
                    this.homeObj.showProjectsList.push(res.data[u]);
                }
                if(res.data.length>3){
                    this.homeObj.loadMoreProjects = true;
                }
            });
        },
        loadMoreProjects:function(){
            var loadNext = -1;
            if((this.homeObj.projectsList.length-this.homeObj.showProjectsList.length)>=6){
                loadNext = 6;
            } else{
                loadNext = this.homeObj.projectsList.length-this.homeObj.showProjectsList.length;
            }
            console.log("load",loadNext)
            var currentProjIndex = this.homeObj.showProjectsList.length;
            for(var u=0;u<loadNext;u++){
                this.homeObj.showProjectsList.push(this.homeObj.projectsList[currentProjIndex]);
                currentProjIndex ++;
            }
            if(this.homeObj.showProjectsList.length == this.homeObj.projectsList.length){
                this.homeObj.loadMoreProjects = false;
            }
        },
        loginUser:function(){
            var data = this.homeObj.loginDetail;
            axios.put('/login?userName='+data.userName+'&password='+data.password).then(res => {
                console.error("login",res);
                if(res.status == 200){
                    this.homeObj.loginDetail.logged = {
                        name:data.userName
                    }
                    localStorage.setItem("loggedInUser",JSON.stringify(this.homeObj.loginDetail.logged));
                    $('.loginMenu').removeClass('show');
                }
            });
        },
        logoutUser:function(){
            $('.loginMenu').removeClass('show');
            localStorage.removeItem("loggedInUser");
            this.homeObj.loginDetail.logged = null;
        },
        onLoginIconClick:function(){
            setTimeout(()=>{
                if($('.homeLoginUser').hasClass('show')){
                    this.homeObj.showSearchField = false;
                } else{
                    this.homeObj.showSearchField = true;
                }
            },10)
        }
    },
    mounted(){
        this.getAllProjectsList();
        this.getAllBrands();
        window.scrollTo(500, 0);
    },
    beforeRouteEnter(to,from,next){
        console.error("enter home route")
        next();
    },
    beforeRouteLeave(to,from,next){
        next();
        console.error("Leave home route")
    }
}
</script>

<style src="@/components/Home/home.css">
</style>