import Vue from 'vue';
import Vuex from 'vuex'
import App from './App.vue';
import 'bootstrap';
import createRouter from 'vue-router';
import createWebHistory from 'vue-router';
import productDetail from './components/ProductDetail/productDetail.vue';
import productList from './components/ProductList/productList.vue';
import productTypeDetail from './components/ProductTypeDetail/productTypeDetail.vue';
import home from './components/Home/home.vue';
import event from './components/Event/event.vue';
import aboutUs from './components/AboutUs/aboutUs.vue';
import catalogues from './components/Catalogues/catalogues.vue';
import contactUs from './components/ContactUs/contactUs';
import faq from './components/FAQ/faq.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import VueMobileDetection from 'vue-mobile-detection'

Vue.config.productionTip = false;
Vue.use(createRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuex);
Vue.use(VueMobileDetection)

const store = new Vuex.Store({
    state: {
        advanceSearch: {
            showProductSearch: false,
            type: 'textSearch'
        },
        imageViewBaseUrl: 'https://kale-admin.com/kale-images/',
        events: null
    },
    mutations: {
        onSetAdvanceSearch(state) {
            state.advanceSearch.showProductSearch = false;
            state.advanceSearch.type = 'textSearch';
        }
    },
});

const router = new createRouter({
    history: createWebHistory,
    routes: [{
            path: '/',
            component: home
        },
        {
            path: '/productDetail',
            component: productDetail
        },
        {
            path: '/productList',
            component: productList
        },
        {
            path: '/productTypeDetail',
            component: productTypeDetail
        },
        {
            path: '/event',
            component: event
        },
        {
            path: '/aboutUs',
            component: aboutUs
        },
        {
            path: '/catalogues',
            component: catalogues
        },
        {
            path: '/contactUs',
            component: contactUs
        },
        {
            path: '/faq',
            component: faq
        },
        {
            path: '/:notFound(.*)',
            redirect: home
        }
    ]
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')