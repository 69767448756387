<template>
    <body>
        <advance-search v-if="$store.state.advanceSearch.showProductSearch"/>
        <main-header/>
        <div id="wrapper">
            <main class="aboutus-main-pb">
                <section>
                    <div class="container-fluid">
                        <h1>Frequently Asked Questions
                            <a href="javascript:;" class="goback" @click="setFAQActivePage()">Back</a>
                        </h1>
                    </div>
                    <div id="accordion" class="faqCard">
                        <div class="card mb5" v-for="(ques) in faq.list" :key="ques.id">
                            <div class="card-header" :id="'headingOne'+ques.id">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" data-toggle="collapse" :data-target="'#collapseOne'+ques.id" aria-expanded="true" aria-controls="collapseOne">
                                        <strong>{{ques.q}}</strong>
                                    </button>
                                </h5>
                            </div>
                            <div :id="'collapseOne'+ques.id" class="collapse" :aria-labelledby="'headingOne'+ques.id" data-parent="#accordion">
                                <div class="card-body ml14">
                                    <span v-if="ques.id!='1'">
                                        <p>{{ques.a}}</p>
                                    </span>
                                    <span v-if="ques.id=='1'">
                                        <a target="_blank" href="https://www.google.com/maps/dir//kale+location+pakistan/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3919092bcbb696c3:0x27c6af6d5588b932?sa=X&ved=2ahUKEwiH5865sMXyAhXrz4UKHa11AfUQ9RcwFHoECE8QBQ">
                                        Lahore DHA Site Map</a><br><br>
                                        <a target="_blank" href="https://www.google.com/maps/dir//kale+pakistan+lahore+college+road/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x39190138a3015887:0x2860c6f568e11ccb?sa=X&ved=2ahUKEwjbteeRtsXyAhVE4OAKHa5CDY4Q9RcwE3oECFUQAw">
                                        Lahore Collage Road Site Map</a><br><br>
                                        <a target="_blank" href="https://www.google.com/maps/dir/31.4802176,74.2981632/kale+pakistan+islamabad/@32.4866819,72.3394034,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x38dff20a6342b14b:0x97996e98d344e780!2m2!1d73.161351!2d33.5191652">
                                        Islamabad Site Map</a><br><br>
                                        <a target="_blank" href="https://www.google.com/maps/dir//kale+pakistan+karachi/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3eb33dc1344daabf:0xa3a6fc9a5e548e28?sa=X&ved=2ahUKEwiWjqKQtcXyAhVLOBoKHZPvD3MQ9RcwHHoECFsQBA">
                                        Karachi Site Map</a><br><br>
                                        <a target="_blank" href="https://www.google.com/maps/dir/31.4802176,74.2981632/kale+pakistan+gujranwala/@31.832817,74.033913,10z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x391f2b4d9090bb27:0x90ce896f182daa51!2m2!1d74.194681!2d32.131796">
                                        Gujranwala Site Map</a><br><br>
                                        <a target="_blank" href="https://www.google.com/maps/dir//kale+pakistan+multan/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x393b3531155d8dbf:0x1cc7876a9af603f2?sa=X&ved=2ahUKEwi8kd7XtcXyAhWPnxQKHezlCtgQ9RcwHHoECGIQBA">
                                        Multan Site Map</a><br><br>
                                        <a target="_blank" href="https://www.google.com/maps/dir//kale+pakistan+faisalabad/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3922686966c2e6b5:0x3868884df01882c0?sa=X&ved=2ahUKEwig8LXntcXyAhVk0uAKHe3uDpsQ9RcwHHoECFoQBA">
                                        Faisalabad Site Map</a><br><br>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
        <footer>
            <main-footer/>
        </footer>
    </body>
</template>

<script>
import advanceSearch from '../AdvanceSearch/advanceSearch';
import mainFooter from '../MainFooter/mainFooter';
import mainHeader from '../MainHeader/mainHeader';
export default {
    components:{
        'advance-search':advanceSearch,
        'main-footer':mainFooter,
        'main-header':mainHeader
    },
    data(){
        return{
            faq:{
                list:[]
            }
        }
    },
    methods:{
        initializeFAQs:function(){
            this.faq.list.push({'id':'1','q':'Where are Kale branches located in Pakistan?',
            'a':'Link to site map.'});

            this.faq.list.push({'id':'2','q':'Does Kale provide in-house architects or customers are suppose to have their own architects?',
            'a':'Kale provides in-house architects for feasibility of customers. If customer prefer to involve their own architects throughout planning stage,'+
            'that’s totally up to customers.'});

            this.faq.list.push({'id':'3','q':'Can we get a detailed specification sheet for the tiles you sell?',
            'a':'Yes, we can obtain technical specification sheets from all our suppliers for all tiles we sell. Contact our Customer Support team.'});

            this.faq.list.push({'id':'4','q':'Can I purchase products online?',
            'a':'Yes you can but Kale suggest its customers to visit showrooms for final approval on tiles.'});

            this.faq.list.push({'id':'5','q':'Will Kale store my credit/debit card information?',
            'a':'Kale priorities its customer security. Kale will be able to store information for future transactions only if customers wants to.'});

            this.faq.list.push({'id':'6','q':'What approach can I use if my transactions failed?',
            'a':'There can be multiple reasons for failed transactions. Please look for the following before confirming: '+
            'Information passed must be accurate i.e.: account details, billing address, password (for net banking) '+
            'Availability of strong internet connection while completing and processing the transaction.'+
            'Please directly call Kale customer service in case of payment charged after failed transaction. Customer service '+
            'representative will keep you updated with roll-back payment within 4 working days. You can also contact us through email id at cs@Kale.com.pk.'});

            this.faq.list.push({'id':'7','q':'How long lasting are the tiles?',
            'a':'Tiles last as long as they are taken care of. Kale does not provide any specific guarantee for their tiles.'});

            this.faq.list.push({'id':'8','q':'Do you guarantee damage to the products during transportation?',
            'a':'Damages that may occur during transportation are not considered as warranty. In case of a problem in our bathroom products, the packaging '+
            'must be opened by the Authorized Service in order to change the products free of charge under the warranty.'});

            this.faq.list.push({'id':'9','q':'How can I install bathroom products?',
            'a':'You can contact our authorized services with expert and experienced teams for the installation of bathroom product groups. You can reach your '+
            'nearest authorized service through the Support option from within our website.'});

            this.faq.list.push({'id':'10','q':'Does Kale provide tiles in just black color?',
            'a':'Unfortunately, Kale does not provide tile in black color. But we do have other options such as Marble Series 80X160.'});

            this.faq.list.push({'id':'11','q':'Can customer use porcelain tiles for sanitary?',
            'a':'It depends on customer preferences and the way they want their castle (home) to look like. Kale keeps their customer preferences as top priority always.'});

            this.faq.list.push({'id':'12','q':'How can I convey my complaint, opinion and suggestions?',
            'a':'You can contact us by filling out the form on our website, calling our customer contact center 111-555-333or through our Authorized services.'});

        },
        setFAQActivePage:function(){
            this.$router.back();
        },
    },
    mounted(){
        window.scrollTo(500, 0);
        this.initializeFAQs();
    }
}
</script>