<template>
    <body>
        <advance-search v-if="$store.state.advanceSearch.showProductSearch"/>
        <main-header/>
        <div id="wrapper">
            <main class="aboutus-main-pb">
                <section>
                    <div class="container-fluid">
                        <h1>About Us
                            <a href="javascript:;" class="goback" @click="setAboutUsActivePage('home')" v-if="aboutUsObj.detailOf==''">Back</a>
                            <a href="javascript:;" class="goback" @click="aboutUsObj.detailOf=''" v-if="aboutUsObj.detailOf!=''">Back</a>
                        </h1>
                    </div>
                    <section>
                        <div class="container-fluid">
                            <div class="littlenav">
                                <ul id="zone_167">
                                    <li id="article_271" :class="{'active':aboutUsObj.toShowInfo=='aboutUs'}"><a title="About Us" href="javascript:;" @click="setAboutUsInfo('aboutUs')"><span>About Us</span></a>
                                    </li>
                                    <li id="article_272" :class="{'active':aboutUsObj.toShowInfo=='ourValues'}"><a title="Our Values" href="javascript:;" @click="setAboutUsInfo('ourValues')"><span>Our Values</span></a>
                                    </li>
                                    <li id="article_272" :class="{'active':aboutUsObj.toShowInfo=='management'}"><a title="Our Values" href="javascript:;" @click="setAboutUsInfo('management')"><span>Management</span></a>
                                    </li>
                                    <li id="article_274" :class="{'active':aboutUsObj.toShowInfo=='investorRelations'}"><a title="Investor Relations" href="javascript:;" @click="setAboutUsInfo('investorRelations')"><span>Investor Relations</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </section>
                <section v-if="aboutUsObj.toShowInfo=='aboutUs'">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                                <div class="thumbnail"> <img src="../../assets/images/271_1_kale-hakkimizda.jpg" alt=""> </div>
                            </div>
                            <div class="col-lg-9 col-md-9 col-sm-8 col-xs-12">
                                <p>
                                    <strong>KALE GROUP</strong><br>
                                    Founded in 1957 with Canakkale Seramik ceramic factories corporation, Kale Group pioneered the formation of the ceramics industry in Turkey, and has become an industry giant internationally with its investments. 
                                    Kale Group, adopting the principles and beliefs of its founder Ibrahim Bodur, has always made contributions to communities for over 50 years through its industrial identity, in education, health, culture, arts, science and sports.
                                    Kale Group is currently comprised of 17 companies, and is regarded as one of the most important industrial enterprises of Turkey with over 5,000 employees, spanning over a geography across Canakkale Seramik to several locations in Turkey to Italy and Russia. Kale Group is Europe's 3rd and the world's 12th largest ceramics manufacturer and in construction chemical sector, it is ranked first in Turkey and the region and 5th in Europe in terms of production and sales volume. Kale Group provides its products to consumers in over 100 countries via more than 400 sales points.
                                    Kale’s strategic geographical position allows faster transfer of technology from Italy since the design and innovation within the tile industry mostly originates from Italy. Kale Turkey produces 72 million square metres every year, making it the largest tile manufacturer in Turkey. Kale’s single location plant is also the largest manufacturing plant in the world. <br><br>
                                    <strong>KALE PAKISTAN FOUNDATION</strong><br>
                                    Kale as a brand in Pakistan is a Turkish tile and ceramic giant that operates through a similar model to flagship stores. Kale Pakistan manufactures complete sanitary solutions, floor tiles, bathroom tiles, kitchen tiles, and state-of-the-art bathroom furniture, giving it a serious competitive advantage over its competition.
                                    In 2014, Kale Pakistan established its first office in Lahore and in 2016 with its first shipment Kale went into business with just one store in Lahore. Since then, the company has opened multiple stores in Lahore, Faisalabad, Multan, Islamabad and Karachi, Gujranwala and plan on opening up more showrooms in Peshawar and other cities in Pakistan. <br><br>

                                    <strong>THE CEO”</strong><br>
                                    Hassan Shah, CEO of Kale Pakistan, a man with a simple mission to revolutionize the tile industry in Pakistan by introducing Kale, a Turkish ceramic company, which is not only manufacturing tiles but also providing complete sanitary solutions. <br><br>
                                    <strong>Brands:</strong><br>
                                    <strong>Canakkale Seramik </strong> is global leader manufacturing, offering exclusive solution and presenting groundbreaking innovations in ceramic tiles. <br>

                                    <strong>Kalesinterflex</strong> is a porcelain ceramic slab with a thickness of only 3mm with dimensions of 1000x3000 mm. Kalesinterflex adds a new perspective to architecture and construction technology.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section v-if="aboutUsObj.toShowInfo=='ourValues'">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                                <div class="thumbnail"> <img src="../../assets/images/271_1_kale-hakkimizda.jpg" alt=""> </div>
                            </div>
                            <div class="col-lg-9 col-md-9 col-sm-8 col-xs-12">
                                <h4>Our Vision</h4>
                                <p>Competing with the world's leading enterprises in all our industries, pioneering technological advances, creating products and services beyond customers expectations and employing skilled labor for this purpose.</p>
                                <h4>Our Mission</h4>
                                <p>We're committed to manufacturing top quality industrial and technological products within the common ground defined by society and our customers, and enriching the lives of all those who work with us.</p>
                                <h4>Our Values</h4>
                                <ul class="floatLeft list">
                                    <li><i class="fas fa-play icon"></i><span>Honesty</span></li>
                                    <li><i class="fas fa-play icon"></i><span>Respect</span></li>
                                    <li><i class="fas fa-play icon"></i><span>Confidence</span></li>
                                    <li><i class="fas fa-play icon"></i><span>Openness</span></li>
                                    <li><i class="fas fa-play icon"></i><span>Social Responsibility</span></li>
                                    <li><i class="fas fa-play icon"></i><span>Teamwork</span></li>
                                    <li><i class="fas fa-play icon"></i><span>Quality</span></li>
                                    <li><i class="fas fa-play icon"></i><span>Continuous Development</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section v-if="aboutUsObj.toShowInfo=='management'">
                    <div class="container-fluid">
                        <div class="row" v-if="aboutUsObj.detailOf==''">
                            <div class="yonetimGw" @click="setDetailOf('ibrahimBodure')">
                                <a class="ygwItem" href="javascript:;" style="height: 581.906px;">
                                    <div class="col-lg-6 col-md-6 col-sm-5 col-xs-12">
                                        <img src="../../assets/images/ibrahim-bodur.jpg" alt="Dr. Hacı İbrahim Bodur">
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-7 col-xs-12">
                                        <figure>
                                            <p class="motto">"Milli sanay, felsefesine gönül verdik, Türk işçisinin el emeği ve göz nuru sanayi malı üreten ve ihraç eden yeni bir ekonominin temellerini attık, zoru hep başardık."</p>
                                            <span>Dr. Hacı İbrahim Bodur</span>
                                            <small>Kale Grubu Kurucusu ve Onursal Başkanı</small>
                                        </figure>
                                    </div>
                                    <div class="titleBox">
                                        <div class="center">
                                            <span>Dr. Hacı İbrahim Bodur</span>
                                            <small>Kale Grubu Kurucusu ve Onursal Başkanı</small>	
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="row" v-if="aboutUsObj.detailOf!=''">
                            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                                <div class="thumbnail">
                                    <img src="../../assets/images/ibrahim-bodur.jpg" alt="">
                                </div>
                            </div>
                            <div class="col-lg-9 col-md-9 col-sm-8 col-xs-12">
                                <h2>Dr. Hacı İbrahim Bodur</h2>
                                <p><strong>Founder of Kale Group</strong></p>
                                    <!-- Article #1 -->
                                <p><strong>Born in 1928 in Çanakkale's Nevruz village, part of the Yenice district which was named by Atatürk in 1934, İbrahim Bodur is a monumental figure who was devoted to Turkey's industrialisation. He is remembered for these words: "Commerce makes people rich but industry makes societies rich."</strong></p>
                                <p>İbrahim Bodur went to primary school at Yenice Primary School and then graduated from Balıkesir High School with honours. </p>
                                <p>He was admitted to İstanbul American Robert College and graduated with high honours from the economy department. He furthered his education in the USA studying business administration and returned to Turkey in 1951.</p>
                                <p>By the end of 1951, he set up the Bodur-Eğinlioğlu Edirnekapı Cotton Yarn Factory together with his father and father-in-law. </p>
                                <p>With the belief that Turkey's development relied on industrialisation and that development had to start in Anatolia, Bodur laid the foundations of Turkey's first ceramic tiles factory in 1957. Transforming the initial limited company, which then had 750 partners, into today's Çanakkale Seramik Fabrikaları Anonim Şirketi (Çanakkale Ceramics Factories Incorporated Company) that has 1,200 partners, Bodur created Turkey's first publicly-held company jointly with family members, employees, dealers and local people.</p>
                                <p>İbrahim Bodur went on to create Kale Group, operating in a wide range of industries including ceramics, building materials, building chemicals, defence and aerospace, machinery and moulds, mining, transportation and real estate.</p>
                                <p>Bodur set up major partnerships both in and outside of Turkey, including foreign partnerships with prestigious organisations such as Siemens, Roca, General Electric and Pratt&amp;Whitney.</p>
                                <p>In 1952, Bodur took part in efforts to establish the İstanbul Chamber of Industry (ISO), Turkey's first independent chamber of industry. Elected to a seat on the Assembly of ISO in 1962, Bodur became a member of the Turkish Chambers and Commodity Exchanges (TOBB) General Assembly in 1968, representing the ISO, and later TOBB's Board of Directors in 1969. For six years, from 1969 to 1975, Bodur served as President and Vice President on TOBB's Presidency Council and chaired TOBB's General Assembly for two consecutive terms.</p>
                                <p>İbrahim Bodur was elected as the President of the Assembly of ISO in 1976. From 1976 to 1992, he served as the President of the Assembly of ISO for 16 years, making him the longest serving person in this capacity.</p>
                                <p>In these roles, İbrahim Bodur made significant contributions to Turkish industry and economy. He was among the founders of the Industrial Council under the Turkish Chambers of Industry and the Turkish Foreign Economic Relations Board (DEIK), representing TOBB. He also founded the Turkish-Italian Business Council, which he chaired for a long period of time.</p>
                                <p>Among one of the six founders of the Turkish Industry and Business Association (TUSIAD), Bodur served as a member of the board of directors for several years. He was also among the founders of the Economic Development Foundation (İKV) as a representative of the ISO.</p>
                                <p>In 2000, the Assembly of ISO honoured Bodur as its honorary member and chairman.</p>
                                <p>İbrahim Bodur also served as a member of Oyak's General Assembly for 21 years.</p>
                                <p>Throughout his professional life spanning more than 65 years, Bodur actively engaged in social and cultural activities and was among the founders of prominent foundations including the ISO Foundation, the Turkish Heart Foundation and TEMA. He personally founded the Kale Ceramics Foundation, making significant contributions and investments in education, health and community. </p>
                                <p>Turkey owes a great many primary schools, Anatolian high schools, vocational healthcare schools, apprenticeship training centres, and dormitories to Bodur, who also generously gave scholarships to tens of thousands of students and provided jobs to thousands of unemployed people. </p>
                                <p>Starting in the 1950's, İbrahim Bodur pioneered the trend of investing in areas that had national resources and work forces rather than developing enterprises in certain large cities. Starting to export industrial products in 1962, Bodur ensured that Turkish industry and products were promoted not only domestically but also internationally.</p>
                                <p>Bodur was decorated with the State Medal of Merit from Czechoslovakia and the Cavaliere del Lavoro, Order of Merit for Labour, from Italy in 1985. In 1997, he received the Order of Merit from the Republic of Turkey and the Distinguished Service Award from the Turkish Grand National Assembly in 2006.</p>
                                <p>In 2007, Bodur was granted the International Aldo Villa award by the Italian Ceramics Association for his contributions to the ceramics industry.</p>
                                <p>In 1994 Karadeniz Technical University and in 1997 Çanakkale Onsekiz Mart University granted honorary doctorate titles to İbrahim Bodur, a man of faith, humility and virtue devoted to national values, who served and contributed significantly in every field to Turkey.</p>
                                <p>A keen lover of music and literature, İbrahim Bodur's poems have been composed into songs by Turkey's leading composers and released in two albums in CD form titled Yıllarca (On and On) and Miras (Legacy).</p>
                                <p>İbrahim Bodur, who contributed significantly to development of Turkish industry, passed away on 23 May 2016 at the age of 88.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section v-if="aboutUsObj.toShowInfo=='investorRelations'">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <p>Turkey's first ceramic tile producer, Çanakkale Ceramic Factories Inc., was established in 1957 and Turkey's first floor tile producer, Kalebodur Ceramic Inc., was established in 1972. While Çanakkale Ceramic became synonymous with quality
                                    in wall tiles, Kalebodur gave the product its name. Çanakkale Ceramic Factories Inc. and Kalebodur Ceramic Industries Inc. merged in 2000 under the name "Kaleseramik Çanakkale Kalebodur Ceramic Industries Inc."</p>

                                <p>Kaleseramik, which continues its production under the Çanakkale Seramik and Kalebodur brands, is the world's biggest ceramics establishment manufacturing in a single field with a production capacity of 66 million square metres/year. </p>

                                <p>Kaleseramik continues production in 50 factories in Çan, which are established on a total of 1.250.000 square metres outdoor space and 650.000 square metres indoor space. These factories, as well as having a yearly production capacity
                                    of 27,5 million square metres for wall tiles, 32 million square metres for floor tiles and 6,5 million square metres for granite ceramics, also have the capacity to produce 15.000 tons insulator and 40.000 tons frit. The factory in
                                    Yozgat, established on 562.000 square metres outdoor space and 24.000 square metres indoor space, begun production in June 2007 and has the capacity to produce 3,6 million square metres floor tiles per year. </p>

                                <p>Kaleseramik produces 1900 varieties of floor tiles in 50 different dimensions and 2200 varities of wall tiles in 60 different dimensions and it offers approximately 200 new products to its consumers every year.<br><br>
                                </p>

                                <h3>Kaleseramik and The Environment</h3>
                                <p>At Kaleseramik Çanakkale Kalebodur Ceramic Industries Incorporated, in addition to the implementation of quality, occupational health and safety management systems, an environmental management system which is in accordance with the terms
                                    of TS EN ISO 14001:2005 standard is also implemented.</p>

                                <p> All the waste resulting from the production and service processes of Kaleseramik is managed in accordance with valid legal requirements. </p>

                                <p>All the resulting process and domestic waste water is purified in two separate treatment facilities in Çan and Semedeli. With the purification process the waste water goes through physical, chemical and biological processes and is released
                                    afterwards to the environment in acordance with legal requirements. <br><br></p>

                                <p> The senior management of Kaleseramik have shown its sensitivity towards the environment with the policy it has established and has announced to all parties.<br><br></p>

                                <h3>Kaleseramik Policy</h3>
                                <p>Kaleseramik Seniour Management, which is manufacturing on the soils where human civilisation has come into being, using this soil;</p>

                                <ul class="list">
                                    <li><i class="fas fa-play icon"></i><span>to determine changing customer wishes and taste in a timely fashion.</span></li>
                                    <li><i class="fas fa-play icon"></i><span>to present to the market products of a high standard of quality to ensure the continuation of customer satisfaction.</span></li>
                                    <li><i class="fas fa-play icon"></i><span>to follow technical and technological developments.</span></li>
                                    <li><i class="fas fa-play icon"></i><span>to ensure that products have at least the minimum technical specifications envisioned by official national and international standards</span></li>
                                    <li><i class="fas fa-play icon"></i><span>to protect the environment and prevent pollution in every stage from investment plans to existing production facilities and from production to transportation.</span></li>
                                    <li><i class="fas fa-play icon"></i><span>to use energy and natural resources efficiently.</span></li>
                                    <li><i class="fas fa-play icon"></i><span>to provide occupational health and safety in the work environment and to take the necessary precautions.</span></li>
                                    <li><i class="fas fa-play icon"></i><span>to comply with valid legal and other requirements.</span></li>
                                    <li><i class="fas fa-play icon"></i><span>to supply the needed resources.</span></li>
                                    <li><i class="fas fa-play icon"></i><span>to support and ensure the participation of all employees.</span></li>
                                    <li><i class="fas fa-play icon"></i><span>to continually improve the effectiveness of quality, environment, occupational health and safety management systems.</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
        <footer>
            <main-footer/>
        </footer>
    </body>
</template>

<script>
import advanceSearch from '../AdvanceSearch/advanceSearch';
import mainFooter from '../MainFooter/mainFooter';
import mainHeader from '../MainHeader/mainHeader';
export default {
    components:{
        'advance-search':advanceSearch,
        'main-footer':mainFooter,
        'main-header':mainHeader
    },
    data(){
        return{
            aboutUsObj:{
                toShowInfo:'aboutUs',
                detailOf:''
            }
        }
    },
    methods:{
        setAboutUsActivePage:function(toRoute){
            if(toRoute == 'home'){
                //this.$router.push('/');
                this.$router.back();
            }
        },
        setDetailOf:function(of){
            if(of){
                this.aboutUsObj.detailOf = of;
            } else{
                this.aboutUsObj.detailOf = '';
            }
        },
        setAboutUsInfo:function(toInfo){
            this.aboutUsObj.toShowInfo = toInfo;
        }
    },
    mounted(){
        window.scrollTo(500, 0);
    }
}
</script>