<template>
    <body class="listResult">
        <advance-search v-if="$store.state.advanceSearch.showProductSearch"/>
        <main-header/>
        <div id="wrapper">
            <main class="productlist-main-pb">
                <section>
                    <div class="container-fluid">
                        <h1>{{products.selectedCategory.name}}<a href="javascript:;" class="goback" @click="setProductListActivePage('home')">Back</a></h1>
                        <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
                            <div class="filterGroup">
                                <form id="app-list-filter" action="">
                                    <div class="fgrow">
                                        <h2>Style<i class="ion-ios-arrow-down"></i></h2>
                                        <select v-model="products.searchFilter.style">
                                            <option value="">Choose</option>
                                            <option v-for="style in products.meta.styles" :key="style.id" :value="style.id" :selected="style.id==products.searchFilter.style">{{style.value}}</option>
                                        </select>
                                    </div>
                                    <div class="fgrow">
                                        <h2>Color<i class="ion-ios-arrow-down"></i></h2>
                                        <div class="fgr">
                                            <label v-for="color in products.meta.colors" :key="color.id"><input type="radio" v-model="products.searchFilter.color" :value="color.id"><i></i><span>{{color.value}}</span></label>
                                        </div>
                                    </div>
                                    <div class="fgrow">
                                        <h2>Size<i class="ion-ios-arrow-down"></i></h2>
                                        <div class="fgr">
                                            <label v-for="size in products.meta.sizes" :key="size.id"><input type="radio" v-model="products.searchFilter.size" :value="size.id"><i></i><span>{{size.value}}</span></label>
                                        </div>
                                    </div>
                                    <div class="fgrow">
                                        <h2>Surface Type<i class="ion-ios-arrow-down"></i></h2>
                                        <select v-model="products.searchFilter.surfaceType">
                                            <option value="">Choose</option>
                                            <option v-for="surface in products.meta.surfaceTypes" :key="surface.id" :value="surface.id" :selected="surface.id==products.searchFilter.surfaceType">{{surface.value}}</option>
                                        </select>
                                    </div>
                                    <div class="fgrow">
                                        <h2>Properties<i class="ion-ios-arrow-down"></i></h2>
                                        <div class="fgr">
                                            <select v-model="products.searchFilter.properties">
                                                <option value="">Choose</option>
                                                <option v-for="prop in products.meta.properties" :key="prop.id" :value="prop.id" :selected="prop.id==products.searchFilter.properties">{{prop.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <button class="app-btn productListClearSearchBtn" @click="onResetSearch()">Clear</button>
                                    <button class="app-btn app-btn-primary productListSearchBtn" @click="onSearchProducts(products.searchFilter.page,products.searchFilter.offset)">Search</button>
                                    <!-- <div class="fgrow">
                                        <h2>Product Type<i class="ion-ios-arrow-down"></i></h2>
                                        <div class="fgr"><select name="ProductType"><option value="">Choose</option><option value="13" selected="true">Ceramic Tiles</option></select></div>
                                    </div>
                                    <div class="fgrow">
                                        <h2>Sub Product Type<i class="ion-ios-arrow-down"></i></h2>
                                        <div class="fgr"><select name="SubProductType"><option value="">Choose</option><option value="495">Ceramic Tiles</option></select></div>
                                    </div> -->
                                </form>
                            </div>
                            <!--filterGroup-->
                        </div>


                        <div class="col-lg-10 col-md-9 col-sm-8 col-xs-12">
                            <div id="app-series-wrapper" class="gateway type5">
                                <div class="app-series-list row">
                                    <a class="col-lg-2 col-md-4 col-sm-6 col-xs-12 gwitem" href="javascript:;" v-for="prod in products.list" :key="prod.id" @click="setProductListActivePage('productDetail',prod)">
                                        <figcaption class="svghover">
                                            <img :src="loadImage(prod.mainImageUuid)" alt="c"> 
                                            <span class="plus"><svg id="circle"><circle cx="60" cy="60" r="35" stroke="white" stroke-width="1" fill="transparent"></circle></svg><em class="line1"></em><em class="line2"></em></span>
                                        </figcaption>
                                        <figure>
                                            <span>{{prod.name}}</span>
                                            <span v-if="products.loggedIn">Price: Rs {{prod.price}} sq/ft</span>
                                        </figure>
                                    </a>
                                </div>
                                <div class="pager">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination">
                                            <li class="page-item">
                                                <a class="page-link" href="javascipt:;" aria-label="Previous" @click="products.paginationData.page>0 && products.searchMode? onSearchProducts(products.paginationData.page-1,products.paginationData.size):getProductsList(products.paginationData.page-1,products.paginationData.size)" :disabled="products.paginationData.page==0">
                                                <span aria-hidden="true">&laquo;</span>
                                                </a>
                                            </li>
                                            <li class="page-item" :class="{'active-page':pg.page==products.paginationData.page}" v-for="pg in products.totalPages" :key="pg.page"><a class="page-link" href="javascript:;" @click="products.searchMode? onSearchProducts(pg.page,products.paginationData.size):getProductsList(pg.page,products.paginationData.size)">{{pg.page+1}}</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="javascipt:;" aria-label="Next" @click="products.paginationData.page<products.totalPages.length && products.searchMode? onSearchProducts(products.paginationData.page-1,products.paginationData.size):getProductsList(products.paginationData.page+1,products.paginationData.size)" :disabled="products.paginationData.page==products.totalPages.length-1">
                                                <span aria-hidden="true">&raquo;</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
        <footer>
            <main-footer/>
        </footer>
    </body>
</template>

<script>
import advanceSearch from '../AdvanceSearch/advanceSearch';
import mainFooter from '../MainFooter/mainFooter';
import mainHeader from '../MainHeader/mainHeader';
import axios from '../../axios-auth';
export default {
    name:'productList',
    components:{
        'advance-search':advanceSearch,
        'main-footer':mainFooter,
        'main-header':mainHeader
    },
    data(){
        return {
            products:{
                meta:{
                    colors:[],
                    styles:[],
                    surfaceTypes:[],
                    sizes:[],
                    properties:[]
                },
                selectedCategory:'',
                list:[],
                totalPages:[],
                paginationData:{
                    page:0,
                    size:24
                },
                searchFilter:{},
                loggedIn:JSON.parse(localStorage.getItem('loggedInUser')) ? JSON.parse(localStorage.getItem('loggedInUser')) : null
            }
        }
    },
    methods:{
        initializeProducts:function(){
            this.products.selectedCategory = JSON.parse(localStorage.getItem('selectedCategory'));
            this.getProductsList(this.products.paginationData.page,this.products.paginationData.size);
            this.getMetaForProducts();
            this.setSearchFilterData();
        },
        getMetaForProducts:function() {
            axios.get('/colors').then(res => {
                this.products.meta.colors = res.data;
            });
            axios.get('/styles').then(res => {
                this.products.meta.styles = res.data;
            });
            axios.get('/size').then(res => {
                this.products.meta.sizes = res.data;
            });
            axios.get('/surface-types').then(res => {
                this.products.meta.surfaceTypes = res.data;
            });
            axios.get('/properties-attrs').then(res => {
                this.products.meta.properties = res.data;
            });
        },
        setProductListActivePage: function(param,obj){
            if(param == 'home'){
                this.$router.back();
                // this.$router.push('/');
            } else if(param == 'productDetail'){
                localStorage.setItem("selectedProduct",JSON.stringify(obj));
                this.$router.push('/productDetail');
            }
        },
        getSetStoreData:function(forC){
            if(forC == 'advanceSearchReset'){
                this.$store.state.advanceSearch.showProductSearch = true;
            }
        },
        getProductsList:function(page,size) {
            var url = '';
            if(this.products.selectedCategory.listOf == 'products'){
                url = '/brands/'+this.products.selectedCategory.uuid+'/products?page='+page+'&size='+size;
            } else{
                url = '/categories/'+this.products.selectedCategory.uuid+'/products?page='+page+'&size='+size;
            }
            axios.get(url).then(res =>{
                console.log("Res List",res);
                if(res.status == 200){
                    this.products.list = res.data.content;
                    this.products.paginationData.page = res.data.pageable.pageNumber;
                    if(!this.products.totalPages.length){
                        for(var i=0; i<res.data.totalPages; i++){
                            this.products.totalPages.push({'page':i});
                        }
                    }
                }
            });
        },
        loadImage:function(uuid) {
            return this.$store.state.imageViewBaseUrl+uuid+'.jpg';
        },
        setSearchFilterData:function() {
            this.products.searchMode = false;
            this.products.searchFilter = {
                page:0,
                offset:24,
                color: '',
                properties: '',
                size: '',
                style: '',
                surfaceType:''
            }
        },
        onResetSearch:function() {
            this.setSearchFilterData();
            this.products.paginationData.page = 0;
            this.products.paginationData.size = 24;
            this.getProductsList(this.products.paginationData.page,this.products.paginationData.size);
        },
        onSearchProducts: function(page,size){
            this.products.paginationData.page = page;
            this.products.paginationData.size = size;
            var data = {};
            var queyString = "";
            queyString += 'page='+this.products.paginationData.page+'&offset='+this.products.paginationData.size;
            data.offset = this.products.paginationData.size;
            data.page = this.products.paginationData.page;
            if(this.products.searchFilter.style!=''){
                data.style = this.products.searchFilter.style;
                queyString += '&style='+this.products.searchFilter.style;
            }
            if(this.products.searchFilter.color!=''){
                data.color = this.products.searchFilter.color;
                queyString += '&color='+this.products.searchFilter.color;
            }
            if(this.products.searchFilter.size!=''){
                data.size = this.products.searchFilter.size;
                queyString += '&size='+this.products.searchFilter.size;
            }
            if(this.products.searchFilter.surfaceType!=''){
                data.surfaceType = this.products.searchFilter.surfaceType;
                queyString += '&surfaceType='+this.products.searchFilter.surfaceType;
            }
            if(this.products.searchFilter.properties!=''){
                data.properties = this.products.searchFilter.properties;
                queyString += '&properties='+this.products.searchFilter.properties;
            }
            console.log("data",data)
            this.products.searchMode = true;
            axios.get('/filterProducts?'+queyString).then(res=>{
                console.log("res",res)
                this.products.list = res.data.content;
                this.products.paginationData.page = res.data.pageable.pageNumber;
                if(page == 0){
                    this.products.totalPages = [];
                }
                if(!this.products.totalPages.length){
                    for(var i=0; i<res.data.totalPages; i++){
                        this.products.totalPages.push({'page':i});
                    }
                }
            });
        }
    },
    mounted(){
        this.initializeProducts();
        window.scrollTo(500, 0);
    }
}
</script>

<style>
</style>