<template>
    <body>
        <advance-search v-if="$store.state.advanceSearch.showProductSearch"/>
        <main-header/>
        <div id="wrapper">
            <main class="event-main-pb">
                <section>
                    <div class="container-fluid">
                        <h1>
                            <span>{{eventObj.name}}</span>
                            <a href="javascript:;" class="goback" @click="setEventActivePage('home')">Back</a>
                        </h1>
                    </div>
                    <!-- ZG Container #1 -->
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                            <!-- <span>Emaar Crescent Bay Towers</span> -->
                                <div class="thumbnail">
                                    <img :src="loadImage(eventObj.mainImageUuid)" alt="c">
                                </div>
                                <!-- <div class="pagetools">
                                    <ul>
                                        <li><a href="#" class="arrow ion-android-arrow-dropdown">Paylaş<span class="ion-share"></span></a>
                                            <ul class="hd">
                                                <li><a href="#"><span class="ion-social-facebook"></span>Facebook</a></li>
                                                <li><a href="#"><span class="ion-social-twitter"></span>Twitter</a></li>
                                                <li><a href="#"><span class="ion-social-pinterest"></span>Pinterest</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div> -->
                            </div>
                            <div class="col-lg-9 col-md-9 col-sm-8 col-xs-12">
                                <!-- Article #1 -->
                                <p><strong>{{eventObj.description}}</strong></p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
        <footer>
            <main-footer/>
        </footer>
    </body>
</template>

<script>
import advanceSearch from '../AdvanceSearch/advanceSearch';
import mainFooter from '../MainFooter/mainFooter';
import mainHeader from '../MainHeader/mainHeader';
export default {
    name:'Event',
    components:{
        'advance-search':advanceSearch,
        'main-footer':mainFooter,
        'main-header':mainHeader
    },
    data(){
        return{
            eventObj:null
        }
    },
    methods:{
        setEventActivePage: function(toRoute){
            if(toRoute == 'home'){
                this.$router.push('/');
            }
        },
        getSetStoreData:function(forC){
            if(forC == 'advanceSearchReset'){
                this.$store.state.advanceSearch.showProductSearch = true;
            }
        },
        loadImage(uuid) {
            return this.$store.state.imageViewBaseUrl+uuid+'.jpg';
        },
    },
    mounted(){
        this.eventObj = this.$store.state.events;
    }
}
</script>

<style scoped>

</style>